/* Auth Banner */
.auth-banner-container {
  text-align: center;
  background-color: #03a87c;
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 120 120'%3E%3Cpolygon fill='%23000' fill-opacity='.1' points='120 0 120 60 90 30 60 0 0 0 0 0 60 60 0 120 60 120 90 90 120 60 120 0'/%3E%3C/svg%3E"); */
  width: 100%;
  background-color: black;
  padding: 1.7rem 0;
  font-family: 'Poppins', sans-serif;
}

.auth-banner-title {
  font-weight: 300;
  /* font-family: cursive; */
  letter-spacing: 2px;
  font-size: 1rem;
  color: white;
  /* letter-spacing: 4px; */
}

.auth-input-container {
  margin: auto;
  width: 50%;
  padding: 3rem;
  border-radius: 10px;
  margin-top: 5rem;
  font-family: 'Poppins', sans-serif;
}

.input-title {
  text-align: center;
  font-size: 2.1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.2rem;
  font-family: 'Poppins', sans-serif;
}

.sign-in-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Poppins', sans-serif;
}

.back-button {
  padding: 0.1rem 1.5rem;
  font-family: 'Poppins', sans-serif;

  font-size: 1rem;
  outline: black 2px solid;
  color: black;
}

.auth-ref {
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
}

@media (max-width: 750px) {
  .auth-input-container {
    width: 100%;
    border-radius: 0;
  }

  .auth-banner-title {
    font-size: 1rem;
    letter-spacing: 01px;
  }

  .sign-in-bottom {
    display: block;
    text-align: center;
  }

  .submit-sign-in {
    color: white;
    background-color: black;
    width: 100%;
  }

  .input-title {
    font-size: 1.7rem;
    justify-content: space-between;
  }

  .back-button {
    margin-left: 2rem;
  }
}

.submit-sign-in {
  color: white;
  background-color: black;
}