@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

.knowledge-tabs {
  margin: 0 auto;
  margin-top: 4rem;
  width: 95%;
  padding-bottom: 5rem;
}

.table-title {
  text-align: center;
  font-size: 2.2rem;
  font-family: 'Roboto Mono', monospace;
}

.table-title-line {
  height: 5px;
  width: 15rem;
  background-color: black;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 1rem;
  border-radius: 30px;
}

.card-container > .ant-tabs-card > .ant-tabs-content {
  margin-top: -16px;
}

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}

/* Current Ayat */
.current-ayat {
  background: rgb(187, 234, 253);
  border-radius: 20px;
}

.current-ayat-title {
  background: rgb(187, 234, 253);
  padding: 0.7rem;
  margin-bottom: 1rem;
  border-radius: 20px;
}

.quran-title {
  margin-bottom: 3rem;
}

.noDisplay {
  display: none;
}

/* Table */
.table {
  width: 80%;
  border-collapse: collapse;
  margin: 1.3rem auto;
}

/* Zebra striping */
.tr:nth-of-type(odd) {
  background: #f4f4f4;
}

.th {
  background: #03a87c;
  color: white;
  font-weight: bold;
}

.td,
.th {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
  font-size: 18px;
}

/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  .table {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  /* Force table to not be like tables anymore */
  .table,
  .thead,
  .tbody,
  .th,
  .td,
  .tr {
    display: block;
    width: 20rem;
    margin: 0 auto;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .tr {
    border: 1px solid #ccc;
    margin-bottom: 1rem;
  }

  .td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  .td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    /* Label the data */
    content: attr(data-column);

    color: #000;
    font-weight: bold;
  }
}

.quran-container {
  margin-top: 5rem;
  text-align: center;
  overflow: scroll;
}

.iframe-quran {
  min-width: 1000px;
  width: 100%;
  height: 1000px;
  border: none;
  overflow: hidden;
}
