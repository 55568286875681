@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhaina+2&display=swap');

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1000;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  /* padding-top: 0.8rem; */
  /* padding-bottom: 0.5rem; */
  max-width: 1200px;
}

.nav-icon {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: black;
}

.nav-logo {
  width: 4.5rem;
  /* margin-bottom: -0.2rem; */
  padding-top: 0.7rem;
}

.nav-link {
  margin: 0 1rem;
  font-size: 1rem;
  font-family: 'Baloo Bhaina 2';
}

.nav-link-top {
  margin: 0 1.5rem;
  font-size: 0.9rem;
  font-family: 'Baloo Bhaina 2';
}

.sign-in-button {
  margin-left: 1rem;
  padding: 0.3rem 0.9rem;
  border-radius: 2px;
  font-weight: 500;
  font-size: 0.9rem;
}

/* Drawer Nav */
.drawer-Nav {
  display: none;
}

@media only screen and (max-width: 915px) {
  .navbar {
    display: none;
  }
  .drawer-Nav {
    display: block;
  }
  /* .drawer-burger {
    color: white;
    background-color: black;
    padding: 0.3rem 0.5rem;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: fixed;
    top: 5%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 100;
  } */
  /* drawer */
  /* start */

  .drawer-burger {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 1000;
  }

  .drawer-burger div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
  }
  .drawer-bars {
    font-size: 2rem;
  }
}

/* navbar scrool hide */
.navbar {
  width: 100%;
  position: fixed;
  top: 0;
  transition: top 0.4s;
}

.navbar--hidden {
  top: -115px;
}

.hoverable:hover {
  cursor: pointer;
}
