.footer-container {
  background-color: #343a40;
  color: #f4f4f4;
}

.footer-text {
  color: #f4f4f4;
  bottom: 0;
  width: 100%;
  padding: 1.5rem 0rem;
  text-align: center;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
}

@media (max-width: 550px) {
  .footer-text {
    font-size: 0.8rem;
  }
}
