@import url(https://fonts.googleapis.com/css2?family=Baloo+Bhaina+2&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap);
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1000;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  /* padding-top: 0.8rem; */
  /* padding-bottom: 0.5rem; */
  max-width: 1200px;
}

.nav-icon {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: black;
}

.nav-logo {
  width: 4.5rem;
  /* margin-bottom: -0.2rem; */
  padding-top: 0.7rem;
}

.nav-link {
  margin: 0 1rem;
  font-size: 1rem;
  font-family: 'Baloo Bhaina 2';
}

.nav-link-top {
  margin: 0 1.5rem;
  font-size: 0.9rem;
  font-family: 'Baloo Bhaina 2';
}

.sign-in-button {
  margin-left: 1rem;
  padding: 0.3rem 0.9rem;
  border-radius: 2px;
  font-weight: 500;
  font-size: 0.9rem;
}

/* Drawer Nav */
.drawer-Nav {
  display: none;
}

@media only screen and (max-width: 915px) {
  .navbar {
    display: none;
  }
  .drawer-Nav {
    display: block;
  }
  /* .drawer-burger {
    color: white;
    background-color: black;
    padding: 0.3rem 0.5rem;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: fixed;
    top: 5%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 100;
  } */
  /* drawer */
  /* start */

  .drawer-burger {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 1000;
  }

  .drawer-burger div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
  }
  .drawer-bars {
    font-size: 2rem;
  }
}

/* navbar scrool hide */
.navbar {
  width: 100%;
  position: fixed;
  top: 0;
  transition: top 0.4s;
}

.navbar--hidden {
  top: -115px;
}

.hoverable:hover {
  cursor: pointer;
}

.footer-container {
  background-color: #343a40;
  color: #f4f4f4;
}

.footer-text {
  color: #f4f4f4;
  bottom: 0;
  width: 100%;
  padding: 1.5rem 0rem;
  text-align: center;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
}

@media (max-width: 550px) {
  .footer-text {
    font-size: 0.8rem;
  }
}

.contact-container {
  padding-top: 5rem;
  margin-bottom: 6rem;
}

.contact-button {
  padding: 0.5rem 1.7rem;
  margin-top: 1rem;
}

.contact-message {
  resize: none;
}

.contact-form-container {
  font-family: 'Poppins', sans-serif;
  border-radius: 10px;
  /* padding: 1rem; */
  display: flex;
  /* box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22); */
}

.input-container {
  font-family: 'Poppins', sans-serif;
  margin: 0.7rem 0;
}

.contact-btn-container {
  text-align: center;
}

/* contact information */
.contact-title {
  margin-bottom: 2rem;
}

.contact-information-container {
  margin-left: 5rem;
}
.contact-dec-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.contact-icon {
  font-size: 1.5rem;
  margin-right: 2rem;
  color: #03a87c;
}

.youtube-icon {
  color: #bb0000;
}

.facebook-icon {
  color: #3b5998;
}

.contact-dex-text {
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: 1px;
}

.contact-link {
  color: #03a87c;
}

.contact-link:hover {
  text-decoration: underline;
  color: #03a87c;
}

.drop-shadow {
  position: relative;
  width: 90%;
  padding: 2rem 0;
  padding-left: 3rem;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0px 19px 38px rgba(0, 0, 0, 0.2), 0px 15px 12px rgba(0, 0, 0, 0.2);
}

.contact-form-container-width {
  width: 50%;
}

/* Contact responsive */
@media (max-width: 870px) {
  .contact-container {
    padding-top: 5rem;
    margin-bottom: 1rem;
  }

  .contact-form-container {
    display: block;
    text-align: center;
  }
  .contact-form-container-width {
    width: 90%;
    margin: 0 auto;
  }

  .drop-shadow {
    position: relative;
    width: 100%;
    padding: 0;
    padding-left: 0;
    padding: 2rem 0;
    box-shadow: none;
  }

  .contact-information-container {
    margin-left: 0;
    margin-top: 5rem;
    margin: 0 auto;
  }

  /* icon */

  .contact-title {
    margin-bottom: 1rem;
    margin-top: 3rem;
  }
  .contact-icon {
    margin-right: 0.5rem;
    font-size: 1rem;
  }

  .contact-dec-container {
    width: 90%;
    margin: 0.5rem auto;
    margin-left: 1.5rem;
  }

  .contact-dex-text {
    font-size: 0.8rem;
    font-weight: 300;
    letter-spacing: 0px;
    text-align: center;
  }
}

/* Fixed/sticky icon bar (vertically aligned 50% from the top of the screen) */
.icon-bar {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

.icon-bar a:hover {
  background-color: rgb(61, 61, 61);
}
.facebook {
  background: #3b5998;
  color: white;
}

.twitter {
  background: #55acee;
  color: white;
}

.google {
  background: #dd4b39;
  color: white;
}

.youtube {
  background: #bb0000;
  color: white;
}

/* Right Contact Button */
.contact-popup {
  position: fixed;
  top: 35%;
  right: 0;
  padding: 4rem 0.4rem;
  background: #3b5998;
  color: #f4f4f4;
  font-weight: 700;
  font-size: 1.2rem;
  z-index: 100;
  transition: 0.3s;
  -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
  transform: rotate(-180deg);
  letter-spacing: 1px;
}

.contact-popup:hover {
  padding-left: 1.4rem;
}

/* Widget */
.prayerTimes {
  height: 358px;
  border: 1px solid #ddd;
  display: block;
  margin: 1rem auto;
  width: 90%;
}

.islamEvents {
  width: 90%;
  height: 410px;
  border: 1px solid #ddd;
  display: block;
  margin: 1rem auto;
  margin-top: 2rem;
}

/* Home About container */
.home-about-container {
  margin-left: 2rem;
}

/* Tiltes */
.home-about-passage-title {
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 1px;
  color: black;
  border-bottom: solid 3px #03a87c;
  display: inline-block;
}

/* Upcoming Events */

.no-current-feild {
  font-size: 1.5rem;
  background-color: #f4f4f4;
  width: 20rem;
  margin: 0 auto;
  margin-top: 2rem;
  border-radius: 20px;
  text-align: center;
  border: solid 2px #03a87c;
}

.event-main-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 1.6rem;
}

.events-container {
  margin-right: 2.5rem;
}

.event-date {
  font-size: 1.5rem;
  color: #03a87c;
}

.event-month {
  letter-spacing: 2px;
  margin-top: -7.5px;
  text-transform: uppercase;
}

.event-sub-description-container {
  display: flex;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 0.85rem;
  letter-spacing: 0.025rem;
  color: rgb(17, 17, 17);
}

.event-description-title {
  font-size: 1.1rem;
  font-weight: 400;
  letter-spacing: 0.9px;
  color: rgb(63, 63, 63);
  margin-bottom: 0.3rem;
}

.event-description-place {
  margin-left: 2rem;
}

.event-icons {
  color: #03a87c;
  margin-right: 0.3rem;
}

.event-line {
  display: none;
}

.extra {
  margin-right: 2rem;
}

/* Home Sections */
.home-about-sections {
  margin-bottom: 7rem;
  margin-top: 3rem;
}

/* facebook title */
.facebook-title {
  font-size: 1.4rem;
  font-weight: 700;
  color: #212529;
  padding-top: 1.6rem;
}

/* Announcements */
.announcements-container {
  margin: 1.5rem 0rem;
  margin-bottom: 3rem;
  margin-right: 4rem;
}

.announcemebnt-sub-container {
  padding: 1rem;
  margin-bottom: 2rem;
  margin-right: 1rem;
  margin-left: -1rem;
}

.announcements-title {
  font-size: 1.1rem;
  color: black;
  font-weight: 500;
  margin-bottom: 0.4rem;
}

.announcements-text {
  line-height: 1.6rem;
  color: #737373;
  letter-spacing: 0.6px;
  font-family: "Poppins", sans-serif;
}

.announcement-dec {
  font-size: 1rem;
  margin-top: -1rem;
  margin-bottom: 2rem;
  font-weight: 700;
  background: #343a40;
  padding: 0.5rem;
  color: white;
  border-radius: 5px;
}

.announcements {
  margin-top: 2rem;
}

.announcements-contact-container {
  background: #343a40;
  color: white;
  padding: 1rem;
  border-radius: 5px;
}

.announcements-contact-title {
  font-size: 1rem;
  font-weight: 600;
}

.announcements-contact-info {
  margin: 0.5rem 0;
  letter-spacing: 1px;
  font-size: 0.9rem;
}

.announcements-contact-info span {
  font-weight: 700;
  color: white;
}

.announcements-contact-footer {
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 1px;
  margin-top: 1rem;
}

/* Top About Sakeenat */
.about-home-container {
  margin-top: 2rem;
}

.main-about-home-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: rgb(63, 63, 63);
  margin: 0.5rem 0;
  margin-top: 2rem;
}

.right-carrot {
  color: #000;
  margin-right: 1rem;
}
.main-about-home-paragraph {
  margin-right: 4rem;
  color: #737373;
  line-height: 1.5rem;
  letter-spacing: 0.6px;
  font-family: "Poppins", sans-serif;
}

.read-more-btn {
  margin-top: 0.3rem;
  margin-bottom: 1.5rem;
  padding: 0.3rem 1rem;
  text-align: right;
  font-family: "Poppins", sans-serif;
}

/* Home Images Container Main */
.image-Container {
  position: relative;
  text-align: center;
  color: white;
  margin-top: 5rem;
}

/* Image Overlay */
.image-overlay::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
}

.home-backgorund-img {
  width: 100%;
  height: 23rem;
}

.image-text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.7rem;
  font-weight: 700;
  width: 75%;
  font-family: cursive;
  letter-spacing: 1px;
}

.image-text-top {
  margin-bottom: 1rem;
}

.image-text-middle {
  font-weight: 700;
  margin: 1rem 0;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}

/* Classes */
.class-container {
  margin-top: 2rem;
}

.class-container-style {
  background: #f4f4f4;
  padding: 1rem;
  margin: 2rem 0;
  border-radius: 5px;
  border: solid 1px lightgray;
}

.class-top-container {
  padding-right: 2rem;
}

.class-main-container {
  margin-bottom: 3rem;
  margin-right: 2rem;
}

.classes-alert {
  font-size: 1rem;
  background: #bb0000;
  color: white;
  font-weight: 700;
  margin-bottom: 2.5rem;
  line-height: 1.5rem;
  padding: 0.5rem;
  border-radius: 5px;
}

.classes-name {
  /* color: #03a87c; */
  color: #000;
  font-weight: 700;
  padding-bottom: 0.4rem;
  font-size: 1.2rem !important;
}

.class-sub-text-container {
  margin-left: 2.3rem;
}

.class-decription-container {
  margin: 0.3rem 0;
}

.class-description-title {
  font-weight: 600;
  color: #212529;
  margin-right: 0.3rem;
}

.class-decription-location {
  /* color: #03a87c; */
  color: #737373;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.6px;
  font-weight: 500;
}

.class-decription-text {
  color: #737373;
  letter-spacing: 0.6px;
  font-family: "Poppins", sans-serif;
}

/* Flyer */
.flyer {
  height: 30rem;
  margin: 1rem;
  transition: 0.2s;
}

.flyer:hover {
  opacity: 0.7;
  cursor: pointer;
}

.flyer-container {
  display: flex;
  margin-right: 2rem;
  margin-top: 2rem;
}

.flyer-img-container {
  position: relative;
  width: 100%;
  margin-right: 1rem;
}

.flyer {
  display: block;
  width: 100%;
  height: auto;
}
/* 
.flyer-img-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.3s ease;
  background-color: rgba(0, 0, 0, 0.3);
}

.flyer-img-container:hover .flyer-img-overlay {
  opacity: 1;
}

.flyer-img-icon {
  color: white;
  font-size: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  color: #2dfcc4;
} */

/* grid container */
.grid-container {
  display: grid;
  grid-template-columns: 2.4fr 1fr;
  grid-gap: 1px 1px;
  gap: 1px 1px;
  grid-template-areas: ". .";
}

.facebook-container {
  width: 320px;
  height: 650px;
  border: none;
  overflow: hidden;
}

@media (max-width: 1000px) {
  .flyer-img-container {
    margin: 0rem;
  }

  .flyer {
    margin: 1rem 0rem;
  }

  .flyer-container {
    display: block;
    margin-right: 2rem;
  }

  .flyer-img-icon {
    display: none;
  }

  .flyer:hover {
    opacity: 0.7;
  }
}

/* media querys */
@media (max-width: 870px) {
  /* flyer */
  .flyer {
    margin: 0rem;
    margin-top: 1rem;
  }
  .flyer-container {
    margin-right: 0rem;
  }
  .grid-container {
    display: block;
  }

  .class-top-container {
    padding: 0;
  }

  .announcemebnt-sub-container {
    margin-right: -1.5rem;
  }

  .announcements-contact-container {
    margin-left: 0;
    margin-right: 0;
  }

  .facebook-container {
    width: 320px;
    height: 500px;
    margin-left: auto; /* Automatic margin from left */
    margin-right: auto; /* Automatic margin from right */
  }

  .home-about-sections {
    width: 90%;
    margin: 5rem auto;
  }

  /* .islam-widget-container {
    width: 50%;
    margin: 0 auto;
  } */
}

/* media querys */
@media (max-width: 750px) {
  /* Icon */
  .icon-bar {
    display: none;
  }

  .home-about-sections {
    margin: 5rem 0;
  }

  /* Class Name */
  .classes-name {
    font-size: 1.1rem;
    letter-spacing: 0px;
  }

  .class-sub-text-container {
    margin: 0;
  }

  .right-carrot {
    margin-right: 0.2rem;
  }

  .class-decription-container {
    margin-left: 1.5rem;
  }

  /* Event */
  .event-main-container {
    display: block;
    text-align: center;
  }

  .events-container {
    margin: 0;
  }

  .event-sub-description-container {
    display: block;
  }

  .event-description-times {
    text-align: center;
  }

  .event-description-place {
    text-align: center;
    margin: 0;
  }
  /* .event-line {
    display: block;
    margin-top: 1rem;
    margin-bottom: -1.5rem;
  } */
}

@media (max-width: 550px) {
  .extra {
    margin-right: 0;
  }

  /* No feild */
  .no-current-feild {
    font-size: 0.9rem;
    width: 18rem;
  }

  /* Classes */
  .anouncements-contact-container {
    margin-right: 0;
  }
  .class-container-style {
    padding: 0.4rem;
  }
  .class-main-container {
    margin-right: 0;
  }

  .class-decription-location {
    font-size: 0.8rem;
    letter-spacing: 0;
  }

  .class-decription-text {
    font-size: 0.8rem;
    letter-spacing: 0;
  }

  .class-description-title {
    font-size: 0.9rem;
    letter-spacing: 0;
  }

  .class-decription-container {
    text-align: left;
  }

  .image-text-container {
    font-size: 1.4rem;
    width: 90%;
  }

  .image-text-middle {
    margin: 1.5rem 0;
  }

  .home-backgorund-img {
    height: 20rem;
  }

  .home-about-passage-title {
    text-align: center;
    margin: 0 auto;
  }

  .home-about-sections {
    text-align: center;
  }

  /* About home */
  .main-about-home-paragraph {
    margin-right: 0;
  }

  .home-about-passage-title {
    font-size: 1.2rem;
  }

  /* announcements */
  .announcements-container {
    margin-right: 0;
  }
  .announcements-text {
    line-height: 1.5rem;
    letter-spacing: 0px;
    text-align: left;
  }
}

.about-container {
  padding-top: 5rem;
  margin-bottom: 4.3rem;
}

.about-banner {
  background-color: rgba(37, 207, 162, 0.2);
  padding: 2.5rem;
  margin-bottom: 3.3rem;
}

.about-text {
  text-align: center;
  font-size: 1.7rem;
  font-weight: 500;
  letter-spacing: 2px;
}

.about-text-container {
  margin: 0rem 10rem;
  margin-bottom: 2.6rem;
}

/* Contact responsive */
@media (max-width: 870px) {
  .about-banner {
    margin-bottom: 2rem;
    padding: 2rem;
  }

  .about-text-container {
    width: 85%;
    margin: 0rem auto;
    margin-bottom: 2.6rem;
  }
}

.admin-home-container {
  margin: 2rem 2rem;
  /* background-color: #e9e9e9; */
  padding: 1rem 2rem;
  border-radius: 5px;
}

.search-lectures {
  width: 100%;
  outline: none;
  box-shadow: none;
  border: solid 1px grey;
  padding: 6px;
  font-size: 1rem;
}



.admin-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-btn {
  padding: 0.4rem 1rem;
  font-weight: 600;
  background-color: black;
  color: white;
}

.admin-title {
  display: inline-block;
  border-bottom: solid 3px #000;
  /* font-size: 1.6rem; */
  color: black;
  font-weight: 600;
}

/* Card  */

.admin-card {
  background-color: white;
  /* margin-top: 1.5rem; */
  /* margin-bottom: 1rem; */
  /* border-radius: 5px; */
  /* border: solid 1px grey; */
  border-bottom: 1px solid grey;
  padding: 10px;
}



.admin-card-head {
  display: flex;
  justify-content: space-between;
  /* border: solid grey 1px; */
  align-items: center;
  padding: 2px;
}

.admin-card-title {
  color: #000;
  /* font-size: 20px; */
  font-weight: 600;
}

.admin-card-date {
  color: grey;
  font-weight: 600;
}

.admin-card-bottom {
  margin-top: 0.5rem;
}

.admin-card-bottom ul {
  padding: 0;
  margin: 0;
  text-align: center;
  margin-top: 1rem;
  border-bottom: solid rgb(56, 56, 56) 2px;
  padding-bottom: 0.7rem;
}

.admin-card-bottom ul li {
  display: block;
  font-weight: 600;
  color: #333;
  list-style: none;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 0.5rem;
  padding: 0.15em 0.4em 0.1em;
  font-size: 15px;
  background: rgba(150, 150, 150, 0.06);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-bottom-width: 2px;
  border-radius: 3px;
  width: 80%;
}

.admin-lecture-container {
  margin-top: 0.5rem;
  font-size: 1rem;
}

.admin-btn-link {
  display: inline-block;
  border: 1px solid grey;
  width: 80px;
  font-size: 0.8rem;
  text-align: center;
  padding: 0.3rem;
  transition: 0.3s;
  margin: 4px;
}

.admin-btn-link:hover {
  color: white;
  background-color: rgb(58, 58, 58);
}

@media (max-width: 670px) {

  /* Admin Nav */
  .admin-nav {
    display: block;
    text-align: center;
  }

  .admin-btn {
    width: 100%;
    margin-top: 1rem;
  }

  .admin-home-container {
    margin: 0;
    /* background-color: #e9e9e9; */
    padding: 1rem;
    margin-bottom: 10rem;
  }

  .admin-card-head {
    display: block;
    /* text-align: center; */
  }

  .slectedoption {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    font-size: 16px;
    /* or adjust based on your design */
    padding: 4px;
    /* add padding for better touch accessibility */
    color: black;
    /* text color */
    background-color: white;
    border: solid 1px grey;
    /* add border for visibility */
    text-align: center;
  }



}


.slectedoption {
  -webkit-appearance: none;
  /* Removes default styling from WebKit browsers */
  /* Removes default styling from Mozilla browsers */
  appearance: none;
  /* Standard way to remove default styling */
  width: 100%;
  font-size: 16px;
  padding: 4px;
  margin-top: 0.5rem;
  color: black;
  text-align: center;
  border: solid 1px grey;
  background-color: white;

}

.admin-form {
  background-color: white;
  padding: 10px;
  margin-top: 0.5rem;
  border: solid 3px rgb(82, 82, 82);
}

.admin-form div input {
  width: 100%;
  border: none;
  border-bottom: 2px black solid;
  outline: none
}

.admin-form div input:-ms-input-placeholder {
  color: #5954549f;
}

.admin-form div input::placeholder {
  color: #5954549f;
}

.admin-form button {
  padding: 5px;
  margin-top: 0.5rem;
  color: white;
  font-weight: bold;
  background-color: white;
  width: 100%;
  background-color: grey;
}

.input-lectures {
  width: 100%;
  margin: 0.3rem 0;
  outline: none;
  border: none;
  border-bottom: #000 solid 2px;
}

.delte-btn {
  background-color: rgb(213, 89, 89);
  padding: 5px 10px;
  margin-bottom: 0.3rem;
  border-radius: 5px;
  color: white;

}

.recording-list {
  background-color: white;
  padding: 10px;
}

.recording-list li {
  list-style: none;
  border-bottom: solid 1px gray;
  margin-bottom: 0.2rem;
  padding: 0 2px;
  font-size: 13px;
}

.dropDowmLectures {
  padding: 0.4rem;
  background-color: white;
  color: #000;
  border: solid 1px grey;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 3rem;
  font-size: 16px;
}

.className {
  text-align: center;
  margin-top: 1rem;
}
.knowledge-tabs {
  margin: 0 auto;
  margin-top: 4rem;
  width: 95%;
  padding-bottom: 5rem;
}

.table-title {
  text-align: center;
  font-size: 2.2rem;
  font-family: 'Roboto Mono', monospace;
}

.table-title-line {
  height: 5px;
  width: 15rem;
  background-color: black;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 1rem;
  border-radius: 30px;
}

.card-container > .ant-tabs-card > .ant-tabs-content {
  margin-top: -16px;
}

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}

/* Current Ayat */
.current-ayat {
  background: rgb(187, 234, 253);
  border-radius: 20px;
}

.current-ayat-title {
  background: rgb(187, 234, 253);
  padding: 0.7rem;
  margin-bottom: 1rem;
  border-radius: 20px;
}

.quran-title {
  margin-bottom: 3rem;
}

.noDisplay {
  display: none;
}

/* Table */
.table {
  width: 80%;
  border-collapse: collapse;
  margin: 1.3rem auto;
}

/* Zebra striping */
.tr:nth-of-type(odd) {
  background: #f4f4f4;
}

.th {
  background: #03a87c;
  color: white;
  font-weight: bold;
}

.td,
.th {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
  font-size: 18px;
}

/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  .table {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  /* Force table to not be like tables anymore */
  .table,
  .thead,
  .tbody,
  .th,
  .td,
  .tr {
    display: block;
    width: 20rem;
    margin: 0 auto;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .tr {
    border: 1px solid #ccc;
    margin-bottom: 1rem;
  }

  .td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  .td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    /* Label the data */
    content: attr(data-column);

    color: #000;
    font-weight: bold;
  }
}

.quran-container {
  margin-top: 5rem;
  text-align: center;
  overflow: scroll;
}

.iframe-quran {
  min-width: 1000px;
  width: 100%;
  height: 1000px;
  border: none;
  overflow: hidden;
}

/* Auth Banner */
.auth-banner-container {
  text-align: center;
  background-color: #03a87c;
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 120 120'%3E%3Cpolygon fill='%23000' fill-opacity='.1' points='120 0 120 60 90 30 60 0 0 0 0 0 60 60 0 120 60 120 90 90 120 60 120 0'/%3E%3C/svg%3E"); */
  width: 100%;
  background-color: black;
  padding: 1.7rem 0;
  font-family: 'Poppins', sans-serif;
}

.auth-banner-title {
  font-weight: 300;
  /* font-family: cursive; */
  letter-spacing: 2px;
  font-size: 1rem;
  color: white;
  /* letter-spacing: 4px; */
}

.auth-input-container {
  margin: auto;
  width: 50%;
  padding: 3rem;
  border-radius: 10px;
  margin-top: 5rem;
  font-family: 'Poppins', sans-serif;
}

.input-title {
  text-align: center;
  font-size: 2.1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.2rem;
  font-family: 'Poppins', sans-serif;
}

.sign-in-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Poppins', sans-serif;
}

.back-button {
  padding: 0.1rem 1.5rem;
  font-family: 'Poppins', sans-serif;

  font-size: 1rem;
  outline: black 2px solid;
  color: black;
}

.auth-ref {
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
}

@media (max-width: 750px) {
  .auth-input-container {
    width: 100%;
    border-radius: 0;
  }

  .auth-banner-title {
    font-size: 1rem;
    letter-spacing: 01px;
  }

  .sign-in-bottom {
    display: block;
    text-align: center;
  }

  .submit-sign-in {
    color: white;
    background-color: black;
    width: 100%;
  }

  .input-title {
    font-size: 1.7rem;
    justify-content: space-between;
  }

  .back-button {
    margin-left: 2rem;
  }
}

.submit-sign-in {
  color: white;
  background-color: black;
}
body {
  background-color: #fff;
  overflow-x: hidden;
  margin: 0 auto;
}

h1,
button,
div {
  padding: 0;
  margin: 0;
}

button {
  border: none;
}

button:hover {
  cursor: pointer;
  outline: none;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.dasboard-body {
  /* background-color: #f4f4f4; */
  width: 100%;
  height: 100%;
  padding-left: 16rem;
}

.dasboard-container {
  padding: 2rem 2rem;
  padding-bottom: 4rem;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1240px;
  }
}

@media (max-width: 870px) {
  .container {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: 1000px) {
  .dasboard-body {
    padding-left: 0;
  }

  .dashboard-navbar-drawer {
    display: block;
  }

  .drawer-bars {
    font-size: 2rem;
  }

  .dashboard-nav-container {
    display: none;
  }
}

.main-color-scheme {
  background-color: #03a87c;
  color: #fff;
  border-radius: 3px;
  transition: 0.3s;
}

.main-color-scheme:hover {
  background-color: #25cfa2;
}

.main-color-scheme:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(37, 207, 162, 0.4);
}

.main-color-scheme-inverted {
  color: #03a87c;
  border: solid 2px #03a87c;
  border-radius: 3px;
  transition: 0.3s;
}

.main-color-scheme-inverted:hover {
  background-color: #d7fff4;
}

/* underline transion */
.middle {
  position: relative;
}

.middle:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.2s ease-in-out;
}

.middle:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.middle-visable:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.2s ease-in-out;
  visibility: visible;
  transform: scaleX(1);
}

.middle:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

/* underline black */
.middle-black {
  position: relative;
}

.middle-black:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.2s ease-in-out;
}

.middle-black:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.middle-visable-black:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.2s ease-in-out;
  visibility: visible;
  transform: scaleX(1);
}

.middle-black:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

