.admin-home-container {
  margin: 2rem 2rem;
  /* background-color: #e9e9e9; */
  padding: 1rem 2rem;
  border-radius: 5px;
}

.search-lectures {
  width: 100%;
  outline: none;
  box-shadow: none;
  border: solid 1px grey;
  padding: 6px;
  font-size: 1rem;
}



.admin-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-btn {
  padding: 0.4rem 1rem;
  font-weight: 600;
  background-color: black;
  color: white;
}

.admin-title {
  display: inline-block;
  border-bottom: solid 3px #000;
  /* font-size: 1.6rem; */
  color: black;
  font-weight: 600;
}

/* Card  */

.admin-card {
  background-color: white;
  /* margin-top: 1.5rem; */
  /* margin-bottom: 1rem; */
  /* border-radius: 5px; */
  /* border: solid 1px grey; */
  border-bottom: 1px solid grey;
  padding: 10px;
}



.admin-card-head {
  display: flex;
  justify-content: space-between;
  /* border: solid grey 1px; */
  align-items: center;
  padding: 2px;
}

.admin-card-title {
  color: #000;
  /* font-size: 20px; */
  font-weight: 600;
}

.admin-card-date {
  color: grey;
  font-weight: 600;
}

.admin-card-bottom {
  margin-top: 0.5rem;
}

.admin-card-bottom ul {
  padding: 0;
  margin: 0;
  text-align: center;
  margin-top: 1rem;
  border-bottom: solid rgb(56, 56, 56) 2px;
  padding-bottom: 0.7rem;
}

.admin-card-bottom ul li {
  display: block;
  font-weight: 600;
  color: #333;
  list-style: none;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 0.5rem;
  padding: 0.15em 0.4em 0.1em;
  font-size: 15px;
  background: rgba(150, 150, 150, 0.06);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-bottom-width: 2px;
  border-radius: 3px;
  width: 80%;
}

.admin-lecture-container {
  margin-top: 0.5rem;
  font-size: 1rem;
}

.admin-btn-link {
  display: inline-block;
  border: 1px solid grey;
  width: 80px;
  font-size: 0.8rem;
  text-align: center;
  padding: 0.3rem;
  transition: 0.3s;
  margin: 4px;
}

.admin-btn-link:hover {
  color: white;
  background-color: rgb(58, 58, 58);
}

@media (max-width: 670px) {

  /* Admin Nav */
  .admin-nav {
    display: block;
    text-align: center;
  }

  .admin-btn {
    width: 100%;
    margin-top: 1rem;
  }

  .admin-home-container {
    margin: 0;
    /* background-color: #e9e9e9; */
    padding: 1rem;
    margin-bottom: 10rem;
  }

  .admin-card-head {
    display: block;
    /* text-align: center; */
  }

  .slectedoption {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    font-size: 16px;
    /* or adjust based on your design */
    padding: 4px;
    /* add padding for better touch accessibility */
    color: black;
    /* text color */
    background-color: white;
    border: solid 1px grey;
    /* add border for visibility */
    text-align: center;
  }



}


.slectedoption {
  -webkit-appearance: none;
  /* Removes default styling from WebKit browsers */
  -moz-appearance: none;
  /* Removes default styling from Mozilla browsers */
  appearance: none;
  /* Standard way to remove default styling */
  width: 100%;
  font-size: 16px;
  padding: 4px;
  margin-top: 0.5rem;
  color: black;
  text-align: center;
  border: solid 1px grey;
  background-color: white;

}

.admin-form {
  background-color: white;
  padding: 10px;
  margin-top: 0.5rem;
  border: solid 3px rgb(82, 82, 82);
}

.admin-form div input {
  width: 100%;
  border: none;
  border-bottom: 2px black solid;
  outline: none
}

.admin-form div input::placeholder {
  color: #5954549f;
}

.admin-form button {
  padding: 5px;
  margin-top: 0.5rem;
  color: white;
  font-weight: bold;
  background-color: white;
  width: 100%;
  background-color: grey;
}

.input-lectures {
  width: 100%;
  margin: 0.3rem 0;
  outline: none;
  border: none;
  border-bottom: #000 solid 2px;
}

.delte-btn {
  background-color: rgb(213, 89, 89);
  padding: 5px 10px;
  margin-bottom: 0.3rem;
  border-radius: 5px;
  color: white;

}

.recording-list {
  background-color: white;
  padding: 10px;
}

.recording-list li {
  list-style: none;
  border-bottom: solid 1px gray;
  margin-bottom: 0.2rem;
  padding: 0 2px;
  font-size: 13px;
}

.dropDowmLectures {
  padding: 0.4rem;
  background-color: white;
  color: #000;
  border: solid 1px grey;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 3rem;
  font-size: 16px;
}

.className {
  text-align: center;
  margin-top: 1rem;
}