body {
  background-color: #fff;
  overflow-x: hidden;
  margin: 0 auto;
}

h1,
button,
div {
  padding: 0;
  margin: 0;
}

button {
  border: none;
}

button:hover {
  cursor: pointer;
  outline: none;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.dasboard-body {
  /* background-color: #f4f4f4; */
  width: 100%;
  height: 100%;
  padding-left: 16rem;
}

.dasboard-container {
  padding: 2rem 2rem;
  padding-bottom: 4rem;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1240px;
  }
}

@media (max-width: 870px) {
  .container {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: 1000px) {
  .dasboard-body {
    padding-left: 0;
  }

  .dashboard-navbar-drawer {
    display: block;
  }

  .drawer-bars {
    font-size: 2rem;
  }

  .dashboard-nav-container {
    display: none;
  }
}

.main-color-scheme {
  background-color: #03a87c;
  color: #fff;
  border-radius: 3px;
  transition: 0.3s;
}

.main-color-scheme:hover {
  background-color: #25cfa2;
}

.main-color-scheme:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(37, 207, 162, 0.4);
}

.main-color-scheme-inverted {
  color: #03a87c;
  border: solid 2px #03a87c;
  border-radius: 3px;
  transition: 0.3s;
}

.main-color-scheme-inverted:hover {
  background-color: #d7fff4;
}

/* underline transion */
.middle {
  position: relative;
}

.middle:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.2s ease-in-out;
}

.middle:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.middle-visable:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.2s ease-in-out;
  visibility: visible;
  transform: scaleX(1);
}

.middle:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

/* underline black */
.middle-black {
  position: relative;
}

.middle-black:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.2s ease-in-out;
}

.middle-black:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.middle-visable-black:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.2s ease-in-out;
  visibility: visible;
  transform: scaleX(1);
}

.middle-black:hover:before {
  visibility: visible;
  transform: scaleX(1);
}
