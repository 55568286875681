.about-container {
  padding-top: 5rem;
  margin-bottom: 4.3rem;
}

.about-banner {
  background-color: rgba(37, 207, 162, 0.2);
  padding: 2.5rem;
  margin-bottom: 3.3rem;
}

.about-text {
  text-align: center;
  font-size: 1.7rem;
  font-weight: 500;
  letter-spacing: 2px;
}

.about-text-container {
  margin: 0rem 10rem;
  margin-bottom: 2.6rem;
}

/* Contact responsive */
@media (max-width: 870px) {
  .about-banner {
    margin-bottom: 2rem;
    padding: 2rem;
  }

  .about-text-container {
    width: 85%;
    margin: 0rem auto;
    margin-bottom: 2.6rem;
  }
}
