.contact-container {
  padding-top: 5rem;
  margin-bottom: 6rem;
}

.contact-button {
  padding: 0.5rem 1.7rem;
  margin-top: 1rem;
}

.contact-message {
  resize: none;
}

.contact-form-container {
  font-family: 'Poppins', sans-serif;
  border-radius: 10px;
  /* padding: 1rem; */
  display: flex;
  /* box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22); */
}

.input-container {
  font-family: 'Poppins', sans-serif;
  margin: 0.7rem 0;
}

.contact-btn-container {
  text-align: center;
}

/* contact information */
.contact-title {
  margin-bottom: 2rem;
}

.contact-information-container {
  margin-left: 5rem;
}
.contact-dec-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.contact-icon {
  font-size: 1.5rem;
  margin-right: 2rem;
  color: #03a87c;
}

.youtube-icon {
  color: #bb0000;
}

.facebook-icon {
  color: #3b5998;
}

.contact-dex-text {
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: 1px;
}

.contact-link {
  color: #03a87c;
}

.contact-link:hover {
  text-decoration: underline;
  color: #03a87c;
}

.drop-shadow {
  position: relative;
  width: 90%;
  padding: 2rem 0;
  padding-left: 3rem;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0px 19px 38px rgba(0, 0, 0, 0.2), 0px 15px 12px rgba(0, 0, 0, 0.2);
}

.contact-form-container-width {
  width: 50%;
}

/* Contact responsive */
@media (max-width: 870px) {
  .contact-container {
    padding-top: 5rem;
    margin-bottom: 1rem;
  }

  .contact-form-container {
    display: block;
    text-align: center;
  }
  .contact-form-container-width {
    width: 90%;
    margin: 0 auto;
  }

  .drop-shadow {
    position: relative;
    width: 100%;
    padding: 0;
    padding-left: 0;
    padding: 2rem 0;
    box-shadow: none;
  }

  .contact-information-container {
    margin-left: 0;
    margin-top: 5rem;
    margin: 0 auto;
  }

  /* icon */

  .contact-title {
    margin-bottom: 1rem;
    margin-top: 3rem;
  }
  .contact-icon {
    margin-right: 0.5rem;
    font-size: 1rem;
  }

  .contact-dec-container {
    width: 90%;
    margin: 0.5rem auto;
    margin-left: 1.5rem;
  }

  .contact-dex-text {
    font-size: 0.8rem;
    font-weight: 300;
    letter-spacing: 0px;
    text-align: center;
  }
}
